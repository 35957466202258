import React, {useState} from 'react'
import HeroSection from '../components/HeroSection'
import Navbar from '../components/navbar'
import Sidebar from '../components/Sidebar'
import Gallery from '../components/Gallery'
import Footer from '../components/Footer'
import Aboutus from '../components/AboutUs'
import Contactus from '../components/SubmitForm'
import Map from '../components/Map'

const Home = () => {
    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} />
            <HeroSection />
            <Aboutus />
            <Gallery />

            <Footer />
            
            
            
            
        </>
    )
}


export default Home
