import styled from 'styled-components'

export const GalleryContainer = styled.div`
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FBFCFC;
    border-color: #273746;
    border-style:solid;
    border-radius: 100px;
    margin: 10px;

    @media screen and (max-width: 1000px){
        height: 1100px;
    }

    @media screen and (max-width: 768px){
        height: 800px;
    }
    @media screen and (max-width: 640px){
        height: 1300px;
    }

`

export const GalleryWrapper = styled.div`
    display: flex;
    margin-top: -2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 15px;
    

    @media screen and (max-width: 824px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 640px){
        grid-template-columns: 1fr;
        padding: 0 20px;
        margin-top: -2rem;
    }

`

export const GalleryCard = styled.div`
    background: #273746;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 500px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    
    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const GalleryIcon = styled.img`
    height: 260px;
    width: 360px;
    
    @media screen and (max-width: 1024px){
        height: 220px;
        width: 290px;
    }

    @media screen and (max-width: 768px){
        height: 150px;
        width: 250px;
    }

    @media screen and (max-width: 640px){
        height: 150px;
        width: 240px;
    }
`

export const GalleryH1 = styled.h1`
    font-size: 2rem;
    color: #273746;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }


`

// export const GalleryH2 = styled.h1`
//     font-size: 1.5rem;
//     margin-bottom: 10px;

// `

// export const GalleryP = styled.p`
//     font-size: 1rem;
//     text-align: center;
    

// `


