import styled from 'styled-components'

export const ContactContainer = styled.div`
    padding: 24px;
    width: 50%;
    

    
    @media screen and (max-width: 768px){
        width: 100%;
    }

    
`

export const FormHeading =styled.h1`
    color: black;
    color: #273746;  
    font-size: 2rem;

    @media screen and (max-width: 768px){
        font-size: 1.5rem;
    }
    
`

export const DetailsContainer = styled.div`
    position: relative;
    display: flex;

    @media screen and (max-width: 768px){
        display: block;
    }
    
`

export const NameField = styled.div`
    padding: 20px;
`

export const EmailField = styled.div`
    
    padding: 20px;
     
`
export const Message = styled.div`
    padding: 10px 20px;
    display: flex;
`
export const SendMessage = styled.textarea`
    width: 470px;
    height: 200px;
`

export const Button = styled.button`
    
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;

`

export const ImageContainer = styled.div`
    padding: 20px;
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;

    @media screen and (max-width: 1000px){
        display: none;
    }
    
`

export const Image = styled.img`
    width: 40%;
    margin-left: 50%;
    margin-top: -20rem;
    height: 100%;
    
    
`
export const TextContainer = styled.h1`
    display: flex;
    justify-content: center;
`

export const Text = styled.h1`
    font-size: 2rem;
`
