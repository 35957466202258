import React, { useState } from 'react'
import BtnNavabar from '../components/BtnNavbar'
import BtnSidebar from '../components/BtnSidebar'
import FoodCategory from '../components/Foodcategory'
import MenuItems from '../components/menu'
import data from '../components/menu/data'
import { TitleContainer, TitleHeading } from '../components/menu/menuElements'
import MenuCategory from '../components/menucategory'


const Menu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>
        <BtnSidebar isOpen={isOpen} toggle={toggle}/>
        <BtnNavabar toggle = {toggle} />
        <div class = "wrapper">
        <MenuCategory />
        </div>
       {
        data.map(cat =>(
            <div id = {cat.fid}>
            <TitleContainer >
               <TitleHeading >{cat.type}</TitleHeading>
            </TitleContainer>
           <div className = "menu-container">
            {cat.food.map(item => (
                <MenuItems key = {item.id} food = {item} />
            ))}
            </div>
            </div>
        ))
        }        
        </>
    )
}

export default Menu
