const data = [
  {
    fid: "appetizers",
    type: "vorspeisen",
    food: [
      {
        id: 1,
        Name: "DAL-SHOBRA",
        Price: 4.9,
        Details: "Indische Linsensuppe | Indian Lentil Soup",
      },

      {
        id: 2,
        Name: "SABZI-SHORBA",
        Price: 4.9,
        Details: "Indische Gemüsesuppe | Indian Vegitable Soup",
      },

      {
        id: 3,
        Name: "LACHSAN-SUPPE",
        Price: 4.9,
        Details: "Indische Knoblauchsuppe | Indian Chicken Soup",
      },
      {
        id: 4,
        Name: "CHICKEN-SHORBA",
        Price: 5.5,
        Details: "Indische Hühnerfleischsuppe | Indian Chicken Soup",
      },
      {
        id: 5,
        Name: "JHEENGA-SHORBA",
        Price: 6.5,
        Details:
          "Indische Garnelensuppe m. Sherry | Indian Shrimp Soup With Sherry",
      },
      {
        id: 6,
        Name: "PUNJABI CHANA CHAT",
        Price: 5.9,
        Details: "Indischer Kichererbsensalat | Indian Chickpeas Salad",
      },
      {
        id: 7,
        Name: "CHICKEN-CHAT DELHI",
        Price: 6.9,
        Details: "Indischer Hühnerfleischsalat | Indian Chicken Salad",
      },
      {
        id: 8,
        Name: "KRABBEN-COCKTAILS BOMBAY",
        Price: 8.5,
        Details: "Mit Kleinem Nan Shrimps Cocktail With Small Nan",
      },
      {
        id: 9,
        Name: "PAPAD",
        Price: 2.9,
        Details:
          "2 Linsenwaffeln m.3 versch. Soßen | 2 lentil wafers with 3 diff. sauces",
      },
      {
        id: 10,
        Name: "VEGETABLE PAKORA",
        Price: 5.5,
        Details: "frisches gemischtes Gemüse | fresh mixed vegetables",
      },
      {
        id: 11,
        Name: "ONIONS BHAJI",
        Price: 5.5,
        Details: "Zwiebeln gewürzt und gebacken | spiced and baked onions",
      },

      {
        id: 12,
        Name: "PANEER-PAKORA",
        Price: 5.9,
        Details: "frischer, hausgemachter Käse | fresh homemade cheese",
      },

      {
        id: 13,
        Name: "FISH-PAKORA",
        Price: 5.9,
        Details: "zartes Fischfilet | tender fish filet",
      },
      {
        id: 14,
        Name: "CHICKEN-PAKORA",
        Price: 5.9,
        Details: "zartes Hühnerfleisch | tender chicken",
      },
      {
        id: 15,
        Name: "VEGETABLE SAMOSA",
        Price: 5.9,
        Details:
          "2 Pastetchen m. frischem Gemüse gefüllt | 2 patties filled with fresh vegetables",
      },
      {
        id: 16,
        Name: "GEMISCHTER VORSPEISENTELLER",
        Price: 12.9,
        Details: "für zwei Personen | for two persons",
      },
      {
        id: 17,
        Name: "CHILI CHICKEN HOT2",
        Price: 8.9,
        Details:
          "Hühnerfleisch auf Rajasthan-Art mit Spezial-Gewürzen | chicken 'Rajasthan-style' with special spices",
      },
      {
        id: 18,
        Name: "JHEENGA CHILI HOT2",
        Price: 11.9,
        Details: "Riesengarnelen in Chili | king prawns in chili",
      },
      {
        id: 19,
        Name: "ACHARI CHICKEN TIKKA HOT1",
        Price: 9.9,
        Details:
          "zartes mariniertes Hühnerfleisch nach traditioneller Art | tender chicken marinated in traditional way",
      },
      {
        id: 20,
        Name: "SALAT DER SAISON",
        Price: 5.5,
        Details:
          "gemischter Salat mit Frenchdressing, auf Wunsch auch mit Essig und Öl | Mixed salad with French dressing or, if prefered, with oil and vinegar",
      },
      {
        id: 21,
        Name: "TOMATENSALAT",
        Price: 5.5,
        Details:
          "mit Zwiebein Essig und Öl | Tomatoe salad with onions, vinegar and oil",
      },
      {
        id: 22,
        Name: "KÄSE SALAT",
        Price: 6.9,
        Details:
          "gemischter Salat mit hausgemachtem Käse mixed salad with homemade cheese",
      },
      {
        id: 23,
        Name: "SALAT INDISCH",
        Price: 9.9,
        Details:
          "mit gebratenen Hühnerbrustfilestreifen, frischen Champignons, Mais, Zwiebein und Joghurtdressing | indian salad with fried chicken breast strips, fresh mushrooms, corn, onions",
      },
      {
        id: 24,
        Name: "KLEINER GEMISCHTER SALAT",
        Price: 3.9,
        Details: "als Beilage | Small mixed salad as a side dish",
      },
      {
        id: 25,
        image: "../public/chickentandoori.jpg",
        Name: "TANDOORI-CHICKEN",
        Price: 13.9,
        Details:
          "Hähnchenschenkel mariniert nach einem berühmten nordindischen Rezpet | chicken legs marinated in exquisite",
      },
      {
        id: 26,
        image: "../public/chickentikka.jpg",
        Name: "CHICKEN TIKKA",
        Price: 14.9,
        Details:
          "zarte, marinierte Hühnerfleischstücke in Joghurt, Ingwer, Knoblauch und Gewürzen gegrillt | marianated filet of chicken in youghurt, ginger, garlic, spices, grilled",
      },
      {
        id: 27,
        image: "../public/haryalimalai.jpg",
        Name: "HARIYALI MALAI KEBAP",
        Price: 14.9,
        Details:
          "zartes, mariniertes Hühnerfleisch mit Spimat-, Minze- und Koriandersoße | tender marinated chicken with spinach, mint and coriander sauce",
      },
      {
        id: 28,
        Name: "KESRI CHICKEN",
        Price: 14.9,
        Details:
          "marinierte Hühnerbrustfilets mit Knoblauch-Joghurt, Ei | marinated chicken breasts with garlic, yoghurt, egg",
      },
      {
        id: 29,
        Name: "PANEER TIKKA",
        Price: 14.9,
        Details:
          "frischer, hausgemachter Käse gegrillt, zubereitet mit Knoblauch, Ingwer und Koriander | fresh homemade cheese grilled with garlic, ginger and coriander",
      },
      {
        id: 30,
        Name: "BOTTI KABAB",
        Price: 19.9,
        Details:
          "zartes, marinierters Lammfilet am Spieß | tender marinated filet of lamb on skewer",
      },
      {
        id: 31,
        Name: "VEGETABLE TANDOORI",
        Price: 13.9,
        Details:
          "hausgemachter indischer Kase mit Blumenkohl, Tomaten, Zwiebein, Zucchini, Auberginen and Paprika am Spieß gegrillt | homemade indian cheese with cauliflower, tomatoes, onions, zucchini, aubergines and pepper grilled on skewers",
      },
      {
        id: 32,
        Name: "JHEENGA TANDOORI",
        Price: 24.9,
        Details:
          "Riesengarnelen in Joghurt und Gewürzen eingelet, knuspring gegrillt | king prawns marinated in yoghurt and spices, grilled crispy",
      },
      {
        id: 33,
        Name: "MIX-GRILL-PLATTE",
        Price: 22.9,
        Details:
          "gemischte Tandoori-Köstlichkeiten | something of all tandoori delicacies",
      },
      {
        id: 34,
        Name: "SPEZIAL-TELLER",
        Price: 18.9,
        Details:
          "Gebratenes Hühner- u. Lammfleisch in frischem Gemüse, mit Reis and Nan | fried chicken and lamb in fresh vegetables with rice and nan",
      },
    ],
  },
  {
    fid: "chicken",
    type: "HUHN-SPEZIALITATEN",
    food: [
      {
        id: 1,
        image: "../../images/menu/chiken/chickenbadam.jpg",
        Name: "CHICKEN BADAM PASANDA",
        Price: 13.9,
        Details:
          "zartes Hühnerfleisch in Nusssoße mit Kokosflocken und gemahlenen Mandeln | tender chicken in nut sauce with shreded coconut and ground almonds",
      },

      {
        id: 2,
        image: "../../images/menu/chiken/chickensabzi.jpg",
        Name: "CHICKEN SABZI",
        Price: 12.9,
        Details:
          "zartes Hühnerfleischstücke in Joghurt, Ingwer, Knoblauch und Gewürzen gegrillt | marianated filet of chicken in youghurt, ginger, garlic, spices, grilled",
      },

      {
        id: 3,
        image: "../../images/menu/chiken/haryalimalai.jpg",
        Name: "KARAHI MURGH",
        Price: 13.5,
        Details:
          "gebrantenes Hühnerfleisch in Currrysoße in der Pfanne serviert | fried chicken in curry sauce, served sizzling hot",
      },
      {
        id: 4,
        image: "../../images/menu/chiken/chickensabzi.jpg",
        Name: "CHICKEN JALFREZI",
        Price: 14.5,
        Details:
          "Hühnerfleisch ohne Knochen gegrillt im Lehhmofen mut Papirka, Tomaten und Zwieblen | chicken bonelss grilled in clay oven with peppers, tomatoes and onions",
      },
      {
        id: 5,
        image: "../public/chickentikka.jpg",
        Name: "CHICKEN VINDALOO",
        Price: 13.5,
        Details:
          "Hühnerfleisch, Spezialgewürze aus GOA | chicken special spices from Goa",
      },
      {
        id: 6,
        image: "../public/chickentikka.jpg",
        Name: "BUTTER CHICKEN",
        Price: 13.9,
        Details:
          "zartes Hühnerfleisch in Butter-Tomaten-Soße | tender chichen in butter-tomato sauce",
      },
      {
        id: 7,
        image: "../public/chickentikka.jpg",
        Name: "CHICKEN TIKKA MASALA",
        Price: 13.9,
        Details:
          "im Lehmofen gegrtilltes Hühnerfliesch in Masala-Soße | chicken, grilled in clay oven Masala-Sauce",
      },
      {
        id: 8,
        image: "../public/chickentikka.jpg",
        Name: "MURGH NILFIRI",
        Price: 13.5,
        Details:
          "zartes Hühnerfliesch mit Korianderblättern in orientalischer Kräutersoße mit Kokos-milch, gruünem, Chili, Spinat und Minze | tebder chicken with coriander leaves in oriental herb sauce with coconut milk greem chili, spinach and mint",
      },
      {
        id: 9,
        image: "../public/chickentikka.jpg",
        Name: "CHILI CHICKEN",
        Price: 13.9,
        Details:
          "Hühnerfliesch auf Rajasthan Art mit spezialgewürzen | chichen Rajasthan Style in a special blend of spices",
      },
      {
        id: 10,
        image: "../public/chickentikka.jpg",
        Name: "MANGO CHICKEN",
        Price: 13.9,
        Details:
          "Hühnerfliesch in frischer Mango-Safran-Chashwenuss-Soße | chicken with fresh mango saffron cashew nut sauce",
      },
      {
        id: 11,
        image: "../public/chickentikka.jpg",
        Name: "CHICKEN KORMA",
        Price: 13.9,
        Details:
          "Hähnchenfleisch in Kokosnuss-Sahne-Soße | tender chicken in coconut-cream-sauce",
      },
      {
        id: 12,
        image: "../public/chickentikka.jpg",
        Name: "HANDI CHICKEN",
        Price: 13.5,
        Details:
          "Hühnerfliesch auf Rajasthan-Art mit Spezialgewürzen | chicken Rajasthani-Style in a special blend of spices",
      },
      {
        id: 13,
        image: "../public/chickentikka.jpg",
        Name: "CHANA CHICKEN",
        Price: 12.9,
        Details:
          "Hühnerfliesch in Kichererbsen und Curry-Masala-Soße | chicken with chickpeas and Curry-Masala-Sauce",
      },
      {
        id: 14,
        image: "../public/chickentikka.jpg",
        Name: "CHICKEN PALAK",
        Price: 13.5,
        Details:
          "Hühnerfliesch mit Spinat in Masalasoße | tender chicken with spinach in Masala-sauce",
      },
    ],
  },
  {
    fid: "beef",
    type: "RIND-SPEZIALITATEN",
    food: [
      {
        id: 1,
        image: "",
        Name: "BEEF CURRY",
        Price: 14.9,
        Details:
          "zartes Rindfleisch in Curry-Soße | Tender beef in curry sauce",
      },

      {
        id: 2,
        image: "",
        Name: "BEEF MASALA",
        Price: 14.9,
        Details:
          "Zartes Rindfleisch in Masala–Soße | Tender beef in Masala–Sauce",
      },

      {
        id: 3,
        image: "",
        Name: "BEEF PALAK",
        Price: 14.9,
        Details:
          "Zartes Rindfleisch mit Rahmspinat nach  Nordindischer Art | tender beef with spinach  North -  Indian style",
      },
      {
        id: 4,
        image: "",
        Name: "BEEF BHUNNA",
        Price: 14.9,
        Details:
          " Tomaten und Röstzwiebeln in kräftiger Soße | Tender beef with tomatoes and fried onions in a tasty sauce",
      },
      {
        id: 5,
        image: "",
        Name: "BEEF VINDALO HOT",
        Price: 14.9,
        Details:
          "Zartes Rindfleisch mit Spezialgewürzen aus Goa | Tender beef with special spices from goa",
      },
      {
        id: 6,
        image: "",
        Name: "BEEF JALFREZI HOT",
        Price: 14.9,
        Details:
          "Zartes Rindfleisch mit Zwiebeln, Tomaten, Paprika und Chili | Tender beef with onions, tomatoes, sweet peppers and chili",
      },
      {
        id: 7,
        image: "",
        Name: "BEEF HYDERABADI",
        Price: 14.9,
        Details: "Eine Spezialit ät aus Hyderabad | A speciality of Hyderabad",
      },
      {
        id: 8,
        image: "",
        Name: "BEEF MANGO",
        Price: 14.9,
        Details:
          "Zartes Rindfleisch in Mango – Safran – Cashewnuss – Soße | Tender beef in mango – saffron – cashewnut sauce ",
      },
    ],
  },
  {
    fid: "lamb",
    type: "LAMM-SPEZIALITATEN",
    food: [
      {
        id: 1,
        image: "",
        Name: "MUTTON MUGHLAI",
        Price: 14.9,
        Details:
          "Zartes Lammfleisch in Mandel – Safran – Sahnesoße | Tender lamb in almond saffron cream sauce",
      },
      {
        id: 2,
        image: "",
        Name: "BHUNNA-GHOST",
        Price: 14.9,
        Details:
          "Gebratenes Lammfleisch mit tomaten in kräftiger soße | Roasted lamb with tomatoes in a spice sauce",
      },
      {
        id: 3,
        image: "",
        Name: "PALAK GHOST",
        Price: 14.9,
        Details:
          "Gebratenes lammfleisch mit spinat nach berühmter nordindischer Art | Roasted lamb with spinach in north indian tradition",
      },
      {
        id: 4,
        image: "",
        Name: "MUTTON KHUMB WALA",
        Price: 14.9,
        Details:
          "Zartes lammfleisch mit frischen Champignons Knoblauch und Ingwer in Mandel – Curry Soße | Tender lamb with fresh mushrooms, garlic and ginger in an almond curry sauce",
      },
      {
        id: 5,
        image: "",
        Name: "MUTTON VINDALO",
        Price: 14.9,
        Details:
          "Zartes Lammfleisch mit kratoffeln und spezialgewüzen aus goa Special spices from goa | Tender lamb with potatoes and special spices from Goa",
      },
      {
        id: 6,
        image: "",
        Name: "KARAHI GOSHT",
        Price: 14.9,
        Details:
          "Febratenes lammfleisch mit paprika und zwiebeln in currysoße | Roasted lamb with sweet peppers onions in curry sauce",
      },
      {
        id: 7,
        image: "",
        Name: "MUTTON NILGRI HOT",
        Price: 14.9,
        Details:
          "Zartes, lammfilet gegrillt im lehmofen mit korianderblättern, grünem chili, minze, spinat und Kra ütern in orientalischer Soße mit Kokosnussmilch | Tender lamb grilled in a clay oven with coriander leaves, green chili, mint, spinach and herbs in oriental sauce with coconut milk",
      },
      {
        id: 8,
        image: "",
        Name: "MUTTON JALFREZI HOT",
        Price: 14.9,
        Details:
          "Zartes lammfilet gegrillt im lehmofen mit paprika, Tomaten und Zwiebeln | Tender lamb fillet grilled with sweet peppers, tomatoes and onions",
      },
      {
        id: 9,
        image: "",
        Name: "LAMM-BOTI MASALA",
        Price: 14.9,
        Details:
          "Zartes lammfilet in masala – Soße gegrillt | Tender lamb filet grilled in Masala – Sauce",
      },
      {
        id: 10,
        image: "",
        Name: "MUTTON KORMA",
        Price: 14.9,
        Details:
          "Zartes Lammfleisch in Kokosnuss – Sahne – Soße | Tender lamb in coconut – cream – sauce",
      },
      {
        id: 11,
        image: "",
        Name: "MANGO LAMM",
        Price: 14.9,
        Details:
          "Zartes lammfleisch in mango – safran – cashewnusssoße | Tender lamb in mango – saffron – cashewnut sauce",
      },
    ],
  },
  {
    fid: "duck",
    type: "ENTE-SPEZIALITATEN",
    food: [
      {
        id: 1,
        image: "",
        Name: "ENTE KHUMB WALA",
        Price: 16.9,
        Details:
          "zartes Entenbrustfilet mit frischen Champignons, Knoblauch und Ingwer in Mandel – Safransoße |  tender duck breast with sweet peppers, tomatoes and onions",
      },

      {
        id: 2,
        image: "",
        Name: "ENTE JALFREZI",
        Price: 16.9,
        Details:
          "Zartes Entenbrustfilet mit Paprika, Tomaten, und Zwiebeln | Tender duck breast with sweet peppers, tomatoes and onions",
      },

      {
        id: 3,
        image: "",
        Name: "ENTE SPEZIAL",
        Price: 16.9,
        Details: "",
      },
      {
        id: 4,
        image: "",
        Name: "MANGO ENTE",
        Price: 16.9,
        Details:
          "Zartes Entenbrustfilet in Mango – Safran – cashewnuss – soße | Tender fuck breast in mango – saffron – cashewnut sauce",
      },
      {
        id: 5,
        image: "",
        Name: "ENTE KORMA",
        Price: 16.9,
        Details:
          "Zartes Entenbrustfilet in Kokosnuss –Sahne – soße | Tender duck breast in coconut – cream – sauce",
      },
      {
        id: 6,
        image: "",
        Name: "ENTE MASALA",
        Price: 16.9,
        Details:
          "Zartes Entenbrustfilet in Mango – Safran – cashewnuss – soße | Tender fuck breast in mango – saffron – cashewnut sauce | Tender duck filet grilled in Masala – Sauce",
      },
    ],
  },
  {
    fid: "fish",
    type: "FISCH-SPEZIALITATEN",
    food: [
      {
        id: 1,
        image: "",
        Name: "FISCH CHILLI",
        Price: 14.9,
        Details: "Seelachsfilet in Chili soße | Coalfisch filet in chili sauce",
      },

      {
        id: 2,
        image: "",
        Name: "FISCH MASALA",
        Price: 14.9,
        Details:
          "Seelachsfilet nach Ostinidscher Art zubereitet  | Coalfisch filet East indian style",
      },

      {
        id: 3,
        image: "",
        Name: "SHRIMP-CURRY",
        Price: 14.9,
        Details:
          "Krabben in Currysoße mit feinen Gewürzen | Shrimps in curry sauce with delicate spices",
      },
      {
        id: 4,
        image: "",
        Name: "JHEENGA-MASALA",
        Price: 22.5,
        Details:
          "Riesengarnelen in kräftiger Masalasoße | King prawns in tasty Masala – Sauce",
      },
      {
        id: 5,
        image: "",
        Name: "JHEENGA KHUMB wALA",
        Price: 22.5,
        Details:
          "Riesengarnelen mit frischen Champignons, Knoblauch und Ingwer in Mandel – Currsoße | King prawns with fresh mushrooms, garlic and ginger in almond curry sauce",
      },
      {
        id: 6,
        image: "",
        Name: "JHEENGA JALFREZI",
        Price: 22.5,
        Details:
          "Riesengarnelen mit paprika, Tomaten, Zwiebeln  | King prawns with sweet peppers, tomatoes, onions",
      },
      {
        id: 7,
        image: "",
        Name: "JHEENGA MANGO",
        Price: 22.5,
        Details:
          "Riesengarnelen in Mango -  Saffron - Cashewnusssoße | King prawns in Mango Saffron Cashew nut sauce",
      },
      {
        id: 8,
        image: "",
        Name: "JHEEMGA CHILLI",
        Price: 22.5,
        Details:
          "Riesengarnelen Rajasthan – Art mit spezialgewürzen | ing prawns Rajasthan style in a special blend of spices",
      },
    ],
  },
  {
    fid: "veg",
    type: "VEGATARISCHE-SPEZIALITATEN",
    food: [
      {
        id: 1,
        image: "",
        Name: "BOMBAY ALU",
        Price: 10.9,
        Details:
          "kartoffeln in kräftiger Zitronengrassoße | potatoes in a tasty lemon sauce",
      },
      {
        id: 2,
        image: "",
        Name: "SHAHI PANEER",
        Price: 12.5,
        Details:
          "Indischer, hausgemachter Käse zubereitet in Tomaten-mandel-Shane-Soße | Indina homemade cheese cooked in tomato-almond cream sauce",
      },
      {
        id: 3,
        image: "",
        Name: "PALAK PANEER",
        Price: 12.5,
        Details:
          "kräftiger Spinat mit hausgemachtem Käse | tasty spinach with homemade chees",
      },
      {
        id: 4,
        image: "",
        Name: "SHAHI BAINGAN",
        Price: 12.5,
        Details:
          "Auberginen mit hausgemachtem Käse in Mandelsoße | eggpants with home made cheese in almond sauce",
      },
      {
        id: 5,
        image: "",
        Name: "MALAI KOFTA",
        Price: 12.9,
        Details:
          "Bällchen aus gehacktem Gemüse, Rosinen u. hausgemachtem Käse | balls from chopped vegetables, rasins and home made cheese",
      },
      {
        id: 6,
        image: "",
        Name: "CHANA MASALA",
        Price: 10.9,
        Details:
          "Kichererbsen in Curry mut frischen Tomaten und ingwer | chickpeas in curry with fresh tomatoes and ginger",
      },
      {
        id: 7,
        image: "",
        Name: "DAL MAKHANI",
        Price: 10.9,
        Details:
          "Indisches nationalgericht, schwarze Linsen in Butter zubereitet | national dish of Indian black lentils, cooked with butter",
      },
      {
        id: 8,
        image: "",
        Name: "KARAHI PANEER",
        Price: 12.5,
        Details:
          "gebratener hausgemachter Käse mit Tomaten, Paprika und Zwiebeln in Currysoße | homemade cheese with tomatoes, onions, sweet pepper grilled in curry sauce",
      },
      {
        id: 9,
        image: "",
        Name: "BHINDI MASALA",
        Price: 12.5,
        Details:
          "frisches indisches Okra-Gemüse in kräftiger Soße | fresh Indian okra vegetables in spicy sauce",
      },
      {
        id: 10,
        image: "",
        Name: "MIXED VEGETABLES",
        Price: 12.5,
        Details: "gemischtes firsches Gemüse | mixed fresh vegetables",
      },
      {
        id: 11,
        image: "",
        Name: "BAINGAN KA BHARTA",
        Price: 12.5,
        Details:
          "frische Auberginen püriert mit Zwiebeln und Tomaten, kräftig, gewürzt | fresh eggplants mashed with tomatoes and onions, spicy",
      },
      {
        id: 12,
        image: "",
        Name: "BAIGAN HAJIPUR",
        Price: 12.9,
        Details:
          "gefüllte Auberginen mit Spizial-gewüzmischung in Mandel-Sahnesoße | stuffed eggplant with special spice mix in almond cream sauce",
      },
      {
        id: 13,
        image: "",
        Name: "PARIKA AUS BIKANER",
        Price: 12.9,
        Details:
          "gefüllte Paprika mit Spezial-Gewürznuschung in Currysoße | stuffed sweet paprika with a speical blend of spices in curry sauce",
      },
      {
        id: 14,
        image: "",
        Name: "ALU GHOBI",
        Price: 12.5,
        Details:
          "Kartoffeln mit Blumenkohl, gewüfelten Timaten und Zwiebeln | potatoes with cauliflower, diced tomatoes and onions",
      },
      {
        id: 15,
        image: "",
        Name: "CHILI PANNER",
        Price: 12.9,
        Details:
          "hausgemachter Käse auf Rajashan art mit Spezialgewürzen | homemade cheese Rajasthan style in a special blend of spieces",
      },
      {
        id: 16,
        image: "",
        Name: "PANEER BUTTER MASALA",
        Price: 12.9,
        Details:
          "hausgemachter Käse in Butter-Tomateb-Soße | homemade cheese with butter-tomato-sauce",
      },
      {
        id: 17,
        image: "",
        Name: "VEGETABLE JALFREZI",
        Price: 12.9,
        Details:
          "Paprika, Tomaten und Zwuebeln aus dem Lehmofen | sweet peppers, tomatoes and onions prepared in a clay oven",
      },
    ],
  },
  {
    fid: "rice",
    type: "REIS-SPEZIALITATEN",
    food: [
      {
        id: 1,
        image: "",
        Name: "MUTTON BIRYANI",
        Price: 14.9,
        Details:
          "Lammfleisch mit mandeln und rosinen | lamb with almonds and raisins",
      },
      {
        id: 2,
        image: "",
        Name: "CHICKEN BIRYANI",
        Price: 13.9,
        Details:
          "Hühnerfleisch mit Mandeln und Rosinen | chicken with almonds and raisins",
      },
      {
        id: 3,
        image: "",
        Name: "BEEF BIRYANI",
        Price: 14.9,
        Details:
          "Rindfleisch mit Mandeln und Rosinen | beef with almonds and raisins",
      },
      {
        id: 4,
        image: "",
        Name: "VEGETABLE BIRYANI",
        Price: 12.9,
        Details:
          "mit firschem Gemüse, mandeln und Rosinen | wtih fresh vegetables, almonds and raisins",
      },
      {
        id: 5,
        image: "",
        Name: "SHAJHANI BIRYANI",
        Price: 17.5,
        Details:
          "mit Hühnerbrust, Lammstreifen, Shrimps und Nüssen | with chicken breast, lamb strips, shrimps and nuts",
      },
      {
        id: 6,
        image: "",
        Name: "JHEENGA BIRYANI",
        Price: 21.9,
        Details:
          "Riesengarnelen mit mandeln und Rosinen | king prawns with almonds and raisins",
      },
      {
        id: 7,
        image: "",
        Name: "HYDERABAD BIRYANI",
        Price: 14.9,
        Details: "Lammstreifen mit Trockenfrüchten | lamb with dired fruit",
      },
    ],
  },
  {
    fid: "thali",
    type: "THALIS",
    food: [
      {
        id: 1,
        image: "",
        Name: "VEGETABLE THALI",
        Price: 14.9,
        Details:
          "3 verschiedene Gemüsegerichte, raita, Salat, Pappad und Basmati-Ries | 3 different vegetable dishest, raita, salad, pappad and Basmati rice",
      },
      {
        id: 2,
        image: "",
        Name: "PUNJABI THALI",
        Price: 17.5,
        Details:
          "2 verschiedene Fleischgerichte,1 Gemüsegericht, Raita, Salat, Pappad und Basamti-Reis | 2 different meat dishes, 1 vegetable dish, ratia, salad, pappad and Basamati rice",
      },
      {
        id: 3,
        image: "",
        Name: "FISCH THALI",
        Price: 19.9,
        Details:
          "3 verschiedene Fischgerichte, Raita, Salat, Pappad und basmati-Reis | 3 different fish dishes, raita, salad, pappad and Basmati rice",
      },
      {
        id: 4,
        image: "",
        Name: "VEGETABLE THALI",
        Price: 28.9,
        Details:
          "6 verschiedene Gemüsegerichte, raita, Salat, Pappad und Basmati-Ries | 6 different vegetable dishest, raita, salad, pappad and Basmati rice",
      },
      {
        id: 5,
        image: "",
        Name: "PUNJABI THALI",
        Price: 33.9,
        Details:
          "4 verschiedene Fleischgerichte,1 Gemüsegericht, Raita, Salat, Pappad und Basamti-Reis | 4 different meat dishes, 1 vegetable dish, ratia, salad, pappad and Basamati rice",
      },
      {
        id: 6,
        image: "",
        Name: "FISCH THALI",
        Price: 33.9,
        Details:
          "6 verschiedene Fischgerichte, Raita, Salat, Pappad und basmati-Reis | 6 different fish dishes, raita, salad, pappad and Basmati rice",
      },
    ],
  },
  {
    fid: "tandoori-bread",
    type: "TANDOORI-BROT-SPEZIALITÄTEN",
    food: [
      {
        id: 1,
        image: "",
        Name: "NAN",
        Price: 2.9,
        Details: "ovales Brot aus Hefeteig | oval raised bread",
      },
      {
        id: 2,
        image: "",
        Name: "BUTTER NAN",
        Price: 3.2,
        Details:
          "ovales Brot aus Hefeteig gefüllt mir Butter | oval raised bread filled with butter",
      },
      {
        id: 3,
        image: "",
        Name: "ROTI",
        Price: 2.9,
        Details: "F;asches Vollkornfladenbrot | Flat wholemeal bread",
      },
      {
        id: 4,
        image: "",
        Name: "PRANTHA",
        Price: 3.5,
        Details:
          "Mehrschichtiges Vollkornbrot mit Butter | Layered wholemeal bread with butter",
      },
      {
        id: 4,
        image: "",
        Name: "MINT PRANTHA",
        Price: 3.9,
        Details: "gefüllt mir frischer Minze | filled with fresh mint",
      },
      {
        id: 5,
        image: "",
        Name: "VEGETABLE PRANTHA",
        Price: 4.5,
        Details: "gefüllt mir frischem Gemüe | filled with fresh vegetables",
      },
      {
        id: 6,
        image: "",
        Name: "PANNER KULCHA",
        Price: 4.5,
        Details:
          "Hefeteigbrot gefüllt mit hausgemachtem Käse  raised bread filled with homemade cheese",
      },
      {
        id: 7,
        image: "",
        Name: "LACHSAN KULCHA",
        Price: 3.9,
        Details:
          "Hefeteigbrot gefüllt mit Knoblauch | raised bread filled with garlic",
      },
      {
        id: 8,
        image: "",
        Name: "ONION KULCHA",
        Price: 3.9,
        Details:
          "Hefeteigbot gefüllt mit Zwiebeln | raised bread filled with onions",
      },
      {
        id: 9,
        image: "",
        Name: "KEEMA NAN",
        Price: 4.6,
        Details:
          "Hefeteigbot gefüllt mit Lammhackfleisch | raised bread filled chopped lamb",
      },
      {
        id: 10,
        image: "",
        Name: "PESHAWRI NAN",
        Price: 4.9,
        Details:
          "Hefeteigbot gefüllt mit Hühnerfleisch, Käse und sultaninen | filled with chicken, chees and sultana",
      },
      {
        id: 11,
        image: "",
        Name: "PLAIN DAHI",
        Price: 2.9,
        Details: "einfacher Joghurt | yoghurt",
      },
      {
        id: 12,
        image: "",
        Name: "ALU RAITA",
        Price: 3.5,
        Details: "Kartoffel Joghurt | potatoe yoghurt",
      },
      {
        id: 13,
        image: "",
        Name: "KHEERE KA RAITA",
        Price: 3.5,
        Details: "Gurken-Joghurt | cucumber yoghurt",
      },
      {
        id: 14,
        image: "",
        Name: "GEMÜSE/VEGETABLE PULAO",
        Price: 6.9,
        Details: "mit Gemüse | with vegetables",
      },
      {
        id: 15,
        image: "",
        Name: "KASMIRI PULAO",
        Price: 6.9,
        Details: "mit Hülsenfrüchten | with pulses",
      },
      {
        id: 16,
        image: "",
        Name: "LEMON PULOA",
        Price: 6.9,
        Details: "mit Zitrone | with lemon",
      },
    ],
  },
  {
    fid: "desserts",
    type: "DESSERTS",
    food: [
      {
        id: 1,
        image: "",
        Name: "MONGO-CREME MIT VANILLEEIS",
        Price: 5.5,
        Details: "Mango cream with vanilla ice cream",
      },
      {
        id: 2,
        image: "",
        Name: "LYCHEES MIT VANILLEEIS",
        Price: 5.5,
        Details: "Lychees with vanilla icecream",
      },
      {
        id: 3,
        image: "",
        Name: "GAJAR HALWA",
        Price: 5.9,
        Details:
          "Griebene Karotten mit Mandeln und Pistazien mit Hnig garniert | garted carrots with almonds, pistachios and honey",
      },
      {
        id: 4,
        image: "",
        Name: "KULFI",
        Price: 5.9,
        Details:
          "Honigmilch mit Maismehl, mandeln und Pisatzien | honey milk with corn flour, almomds and pistachios",
      },
      {
        id: 5,
        image: "",
        Name: "GULAB JAMUN",
        Price: 5.5,
        Details:
          "Bällchen aus Milch und Quark in Honig gebacken | dumplings made of milk and curd cheese baked in honey",
      },
      {
        id: 6,
        image: "",
        Name: "GANESHA-TELLER",
        Price: 11.9,
        Details:
          "für 2 Personen, lassen Sie sich überraschen | a suprise for you",
      },
    ],
  },
];

export default data;
