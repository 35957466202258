import React from "react";
import { FooterContainer, FooterItems, Details } from "./FooterElements";
const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterItems>
          <Details>
            Stadtplatz 54 84489 Burghausen ● Telefone: +49 (0) 8677-91 65 225
          </Details>
          <Details>
            <strong>Neue Öffnungszeiten:</strong>
          </Details>
          <Details>
            ● Donnerstag - Dienstag (11:30 - 14:30 | 17:30 - 23:30)
          </Details>
          <Details>● Mittwoch (Ruhe Tag)</Details>
        </FooterItems>
      </FooterContainer>
    </>
  );
};

export default Footer;
