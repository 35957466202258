import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {Link} from 'react-router-dom'

export const BtnSidebarContainer = styled.aside`
    position: fixed;
    z-index:999;
    width: 100%;
    height: 100%;
    background: #CD6155;
    display: grid;
    align-items: center;
    top:0;
    left:0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => ( isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => ( isOpen ? '0' : '-100%')};

`;

export const BtnCloseicon = styled(FaTimes)`
    color: #fff;
`;

export const BtnIcon = styled.div`
    position: absolute;
    top:1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const BtnSidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2,80px);
    text-align: center;

    @media screen and(max-width: 480px){
        grid-template-rows: repeat(2,60px);
    }
`;

export const BtnSidebarWrapper = styled.div`
    color: #fff;
`;

export const BtnSidebarmenuDownload = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #FBFCFC;
    cursor: pointer;

    &:hover{
        color: #F1B24A;
        transition: 0.2s ease-in-out;
    }

`;

export const BtnCallButtonView = styled.a`
    border-radius: 50px;
    background: #F2D7D5;
    white-space: nowrap;
    padding: 16px 64px;
    color: #273746;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #9DC88D;
        color: #fff;


    }
`;

export const BtnSidebarLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #FBFCFC;
    cursor: pointer;

    &:hover{
        color: #F1B24A;
        transition: 0.2s ease-in-out;
    }
`;

export const BtnSidebarWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: -25rem;
`;


