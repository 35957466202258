import styled from 'styled-components'

export const FooterContainer = styled.div`
    background: #CD6155;
    border-radius: 10px;
    margin: 5px;

`

export const FooterItems = styled.div`
    width: 100% auto;
    height: 150px auto;
    padding: 10px;
    position: relative;
    @media screen and (max-width: 768px){
        padding: 15px;
    }
    
    
`

export const Details = styled.p`
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #FBFCFC;
    padding: 5px;
`
