import React from 'react'
import GANESHAMENUPDF from '../../Files/GANESHA INDISCHES RESTAURANT FULL MENU.pdf';
import { BtnCallButtonView, BtnCloseicon, BtnIcon, BtnSidebarContainer, BtnSidebarLink, BtnSidebarMenu, BtnSidebarmenuDownload, BtnSidebarWrap, BtnSidebarWrapper } from './BtnSiderbarElements';

const BtnSidebar = ({isOpen,toggle})=> {
    return(
        <BtnSidebarContainer isOpen = {isOpen} onClick={toggle}>
            <BtnIcon onClick = {toggle}>
                <BtnCloseicon />
            </BtnIcon>
            <BtnSidebarWrapper>
                <BtnSidebarMenu>
                    <BtnSidebarLink to='/' onClick={toggle} offset={-80}>Home</BtnSidebarLink>
                    <BtnSidebarmenuDownload href = {GANESHAMENUPDF} download="GANESHA RESTAURANT MENU" onClick={toggle}>Menu<span style={{fontweight: '900'}}>⤓</span></BtnSidebarmenuDownload>
                </BtnSidebarMenu>
            </BtnSidebarWrapper>
            <BtnSidebarWrap>
                <BtnCallButtonView href = "tel:+498677-9165225"><span style={{color: '#CD6155'}}>📞</span>+49 8677-91 65 225</BtnCallButtonView>
            </BtnSidebarWrap>
        </BtnSidebarContainer>
    )
}

export default BtnSidebar;
