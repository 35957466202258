import styled from 'styled-components';
import { Link } from 'react-scroll';

export const MenuNav = styled.nav`
    background: #FBFCFC;
    height: 80px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    position: fixed;
    width: 100%;
    z-index: 9;
    

    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
   }

   &::-webkit-scrollbar-thumb {
      background-color: #F2D7D5;
      border-radius: 3px;
   }
    

    @media screen and (max-width: 1024px){
       width: 100%;
       left: 0%;
    }
    @media screen and (max-width: 768px){
       width: 100%;
       left: 0%;
    }

`

export const MenuNavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    z-index: 1;
    padding: 1rem;
    max-width: 1100px;
    @media screen and (max-width: 1024px){
       width: 100%;
       left: 0%;
    }
    @media screen and (max-width: 768px){
       width: 100%;
       left: 0%;
    }

`

export const MenuItems = styled.ul`
    display:flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    text-align: center;
    width: 100%;
   
    
    @media screen and (max-width: 1024px){
       width: 100%;
       left: 0%;
    }
    @media screen and (max-width: 768px){
       width: 100%;
       left: 0%;
    }
`

export const MenuItem = styled.li`
    height: 80px;
    display: flex;
    justify-content: center;
    margin: 0.5rem;
    align-items: center;
    width: 100%;
    padding: 1rem;
    @media screen and (max-width: 1024px){
       width: 100%;
       left: 0%;
    }
    @media screen and (max-width: 768px){
       width: 100%;
       left: 0%;
    }
  
`
export const MenuBtn = styled.nav`
    display: flex;
    align-items: center;
    width: 100%;

 @media screen and (max-width: 1024px){
       width: 100%;
       left: 0%;
    }
    @media screen and (max-width: 768px){
       width: 100%;
       left: 0%;
    }

`

export const MenuBtnLink = styled(Link)`
    border-radius: 50px;
    background: #F2D7D5;
    white-space: nowrap;
    padding: 10px 20px;
    color: #273746;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    width: 100%;
    font-weight: bold;

    @media screen and (max-width: 1024px){
       font-size: 15px;
    }
    @media screen and (max-width: 768px){
       font-size: 13px;
    }

    &:hover{
        transition: akk 0.2s ease-in-out;
        background: #9DC88D;
        color: #FBFCFC;
    }
`

