import React from 'react'
import { FoodContainer, FoodDetails, FoodHeading, FoodPrice, FoodDescription, FoodImage } from './menuElements'


const MenuItems = (props) => {
    const {food} = props;
    return (
        <>
        <FoodContainer key = {food.id} >
            <FoodDetails>
                <FoodPrice>€ {food.Price}</FoodPrice>
                <FoodHeading>{food.Name}</FoodHeading>
                <FoodDescription>{food.Details}</FoodDescription>
            </FoodDetails>
        </FoodContainer>
        </>
    )
}

export default MenuItems
