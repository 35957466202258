import React from 'react';
import {FaBars} from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn,MenuDownload,CallButton} from './navbarelements';
// import GANESHAMENUPDF from '../../Files/GANESHA INDISCHES RESTAURANT FULL MENU.pdf';

const Navbar = ({toggle}) => {

    // const[scrollNav, setScrollNav] = useState(false);

    // const changeNav = () => {
    //     if(window.scrollY >= 740){
    //         setScrollNav(true);
    //     }else{
    //         setScrollNav(false);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll',changeNav);
    // }, []);

    const toggleHome =() =>{
        scroll.scrollToTop();
    }
    return (
        <>
            {/* scrollNav={scrollNav} */}
            <Nav> 
                <NavbarContainer>
                    <NavLogo /*href ="" */ to='/' onClick={toggleHome}>GANESHA</NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to = 'home'
                                 smooth={true} duration={900} spy={true} exact ='true' offset={-80}
                                >Startseite</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to = 'about'
                                smooth={true} duration={900} spy={true} exact ='true' offset={-80}
                                >Über uns</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to = 'gallery'
                                smooth={true} duration={900} spy={true} exact ='true' offset={-80}
                                >Galerie</NavLinks>
                            </NavItem>
                            {/* <NavBtn>
                                <MenuDownload href ={GANESHAMENUPDF} download="GANESHA RESTAURANT MENU">Speisekarte <span style={{fontWeight:"900" }}>⤓</span></MenuDownload>
                            </NavBtn> */}
                            <NavBtn>
                                <CallButton href ="tel:+498677-9165225"><span style={{color: '#CD6155'}}>📞</span>+49 8677-91 65 225</CallButton>
                            </NavBtn>
                        </NavMenu>
                        
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar;

