import React from 'react'
import Icon1 from '../../images/GANESHA INDISCHES RESTAURANT HOME.jpg'
import Icon2 from '../../images/GANESHA INDISCHES RESTAURANT ENTRANCE.jpg'
import Icon3 from '../../images/GANESHA INDISCHES RESTAURANT INSIDE DINING.jpg'
import Icon4 from '../../images/GANESHA INDISCHES RESTAURANT OUTAREA.jpg'
import Icon5 from '../../images/GANESHA INDISCHES RESTAURANT OUTER VIEW.jpg'
import Icon6 from '../../images/GANESHA INDISCHES RESTAURANT OUTER DINE.jpg'
import { GalleryContainer,GalleryH1,GalleryWrapper,GalleryCard,GalleryIcon,GalleryH2,GalleryP } from './GalleryElements';

const Gallery = () => {
    return (
        <GalleryContainer id ="gallery">
            <GalleryH1>Galerie</GalleryH1>
            <GalleryWrapper>
                <GalleryCard>
                    <GalleryIcon src={Icon1}/>
                    {/* <GalleryH2>Delighted with pure indian spices</GalleryH2> */}
                    {/* <GalleryP>Best Quality Food</GalleryP> */}
                </GalleryCard>
                <GalleryCard>
                    <GalleryIcon src={Icon2}/>
                    {/* <GalleryH2>Delighted with pure indian spices</GalleryH2> */}
                    {/* <GalleryP>Best Quality Food</GalleryP> */}
                </GalleryCard>
                <GalleryCard>
                    <GalleryIcon src={Icon3}/>
                    {/* <GalleryH2>Delighted with pure indian spices</GalleryH2> */}
                    {/* <GalleryP>Best Quality Food</GalleryP> */}
                </GalleryCard>
                <GalleryCard>
                    <GalleryIcon src={Icon6}/>
                    {/* <GalleryH2>Delighted with pure indian spices</GalleryH2> */}
                    {/* <GalleryP>Best Quality Food</GalleryP> */}
                </GalleryCard>
                <GalleryCard>
                    <GalleryIcon src={Icon4}/>
                    {/* <GalleryH2>Delighted with pure indian spices</GalleryH2> */}
                    {/* <GalleryP>Best Quality Food</GalleryP> */}
                </GalleryCard>
                <GalleryCard>
                    <GalleryIcon src={Icon5}/>
                    {/* <GalleryH2>Delighted with pure indian spices</GalleryH2> */}
                    {/* <GalleryP>Best Quality Food</GalleryP> */}
                </GalleryCard>
                
            </GalleryWrapper>
        </GalleryContainer>
    )
}

export default Gallery
