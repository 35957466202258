import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    /* background: ${({scrollNav}) => (scrollNav ? '#CD6155':'transparent')}; */
    background: #CD6155 ;
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }

`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;

`

export const NavLogo = styled(LinkR)`
    color: #FBFCFC;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`
export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position:absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color:#FBFCFC;
    }
`
export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px;
    margin: 0.5rem;
`
export const NavLinks = styled(LinkS)`
    color: #FBFCFC;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    border-radius: 20px;

    &.active{
        transition: all 0.2s ease-in-out;
        background:#F2D7D5;
        color: #273746;
    }
`

// export const MenuDownload = styled.a`
//     border-radius: 50px;
//     /* background: #F2D7D5; */
//     white-space: nowrap;
//     padding: 10px;
//     color: #FBFCFC;
//     font-size: 16px;
//     outline: none;
//     border: #FBFCFC;
//     border-style: solid;
//     cursor: pointer;
//     transition: all 0.2s ease-in-out;
//     text-decoration: none;

//     &:hover{
//         transition:all 0.2s ease-in-out;
//         background: #F2D7D5;
//         color: #273746;
//     }
// `
export const CallButton = styled.a`
    border-radius: 50px;
    /* background: #F2D7D5; */
    white-space: nowrap;
    padding: 10px;
    color: #FBFCFC;
    font-size: 16px;
    outline: none;
    border: #FBFCFC;
    border-style: solid;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #F2D7D5;
        color: #273746;
    }
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    padding: 10px;
    @media screen and (max-width: 768px){
        display: none;
    }

`
// export const NavBtnLink = styled(LinkR)`
//     border-radius: 50px;
//     background: #F2D7D5;
//     white-space: nowrap;
//     padding: 10px 20px;
//     color: #273746;
//     font-size: 16px;
//     outline: none;
//     border: none;
//     cursor: pointer;
//     transition: all 0.2s ease-in-out;
//     text-decoration: none;

//     &:hover{
//         transition: akk 0.2s ease-in-out;
//         background: #9DC88D;
//         color: #FBFCFC;
//     }
// `