import React from 'react'
import {FaBars} from 'react-icons/fa';
import {BtnNav, BtnNavbarContainer, BtnNavLogo, BtnMobileIcon, BtnNavMenu, BtnNavBtn, BtnNavBtnLink,BtnCallButton,BtnMenuDownload} from './BtnNavbarElements';
import GANESHAMENUPDF from '../../Files/GANESHA INDISCHES RESTAURANT FULL MENU.pdf';

const BtnNavabar = ({toggle}) => {
    return (
        <>
            <BtnNav>
                <BtnNavbarContainer>
                    <BtnNavLogo /*href ="" */ to='/'>GANESHA</BtnNavLogo>
                        <BtnMobileIcon onClick={toggle} >
                            <FaBars />
                        </BtnMobileIcon>
                        <BtnNavMenu>
                        <BtnNavBtn>
                            <BtnNavBtnLink to="/">Home</BtnNavBtnLink>
                        </BtnNavBtn>
                        <BtnNavBtn>
                            <BtnMenuDownload href ={GANESHAMENUPDF} download="GANESHA RESTAURANT MENU">Speisekarte <span style={{fontWeight:"900" }}>⤓</span></BtnMenuDownload>
                        </BtnNavBtn>
                        <BtnNavBtn>
                            <BtnCallButton href ="tel:+498677-9165225"><span style={{color: '#CD6155'}}>📞</span>+49 8677-91 65 225</BtnCallButton>
                        </BtnNavBtn>
                        </BtnNavMenu>
                </BtnNavbarContainer>
            </BtnNav>
        </>
    )
}

export default BtnNavabar
