import React from 'react'
import { MenuBtn, MenuBtnLink, MenuItem, MenuItems, MenuNav, MenuNavContainer } from './menucategoryElements'

const MenuCategory = () => {

    return(
        <MenuNav>
            <MenuNavContainer>
                <MenuItems>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "appetizers"  offset = {-160}>VORSPEISEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "chicken" offset = {-160}>HUHN-SPEZIALITATEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "beef"  offset = {-160}>RIND-SPEZIALITATEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "lamb"  offset = {-160}>LAMM-SPEZIALITATEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "duck"  offset = {-160}>ENTE-SPEZIALITATEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "fish"  offset = {-160}>FISCH-SPEZIALITATEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "veg"  offset = {-160}>VEGATARISCHE-SPEZIALITATEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "rice"  offset = {-160}>REIS-SPEZIALITATEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "thali"  offset = {-160}>THALIS</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "tandoori-bread"  offset = {-160}>TANDOORI-BROT-SPEZIALITÄTEN</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                    <MenuItem>
                        <MenuBtn>
                            <MenuBtnLink to = "desserts" offset = {-160}>DESSERTS</MenuBtnLink>
                        </MenuBtn>
                    </MenuItem>
                </MenuItems>
            </MenuNavContainer>
        </MenuNav>
    )
}

export default MenuCategory;