import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'


export const BtnNav = styled.nav`
    //background: #4D774E;
    background: #CD6155 ;
    height: 80px;
    //margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }

`

export const BtnNavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;

`

export const BtnNavLogo = styled(LinkR)`
    color: #FBFCFC;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`
export const BtnMobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position:absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color:#FBFCFC;
    }
`
export const BtnNavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const BtnNavBtn = styled.nav`
    display: flex;
    align-items: center;
    padding: 5px;

    @media screen and (max-width: 768px){
        display: none;
    }

`

export const BtnCallButton = styled.a`
    border-radius: 50px;
    /* background: #F2D7D5; */
    white-space: nowrap;
    padding: 10px;
    color: #FBFCFC;
    font-size: 16px;
    outline: none;
    border: #FBFCFC;
    border-style: solid;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #F2D7D5;
        color: #273746;
    }
`
export const BtnMenuDownload = styled.a`
    border-radius: 50px;
    /* background: #F2D7D5; */
    white-space: nowrap;
    padding: 10px;
    color: #FBFCFC;
    font-size: 16px;
    outline: none;
    border: #FBFCFC;
    border-style: solid;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition:all 0.2s ease-in-out;
        background: #F2D7D5;
        color: #273746;
    }
`


export const BtnNavBtnLink = styled(LinkR)`
    border-radius: 50px;
    /* background: #F2D7D5; */
    white-space: nowrap;
    padding: 10px 18px;
    color: #FBFCFC;
    font-size: 16px;
    outline: none;
    border: #FBFCFC;
    border-style: solid;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #F2D7D5;
        color: #273746;
    }
`