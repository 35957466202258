import React from 'react'
import { CloseIcon, Icon, SidebarContainer, SidebarLink, SidebarRoute, SidebarWrapper, SidebtnWrap, SidebarMenu,SidebarLinkMenuDownload,CallButtonMobileView } from './SidebarElements';
import GANESHAMENUPDF from '../../Files/GANESHA INDISCHES RESTAURANT FULL MENU.pdf';

const Sidebar = ({isOpen,toggle}) => {
    return (
        <SidebarContainer isOpen = {isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon  />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu >
                    <SidebarLink to ='home' onClick={toggle} offset={-80}>Home</SidebarLink>
                    <SidebarLink to ='about' onClick={toggle} offset={-80}>About</SidebarLink>
                    <SidebarLink to ='gallery' onClick={toggle} offset={-80}>Gallery</SidebarLink>
                    <SidebarLinkMenuDownload href ={GANESHAMENUPDF} download="GANESHA RESTAURANT MENU" onClick={toggle}>Menu<span style={{fontweight: '900'}}>⤓</span></SidebarLinkMenuDownload>
                </SidebarMenu>
                <SidebtnWrap>
                    <CallButtonMobileView href ="tel:+498677-9165225"><span style={{color: '#CD6155'}}>📞</span>+49 8677-91 65 225</CallButtonMobileView>
                </SidebtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;
