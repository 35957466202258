import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const Button =styled(Link)`
    border-radius: 50px;
    background: ${({bgtrans}) => (bgtrans ? '' : '#F2D7D5')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#273746' : '#FBFCFC')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: #FBFCFC;
    border-style: solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({bgtrans}) => (bgtrans ? '#F2D7D5' : '#fff')};
        color: ${({dark}) => (dark ? '#fff' : '#273746')};
    }

    `

export const PDFButton =styled.a`
border-radius: 50px;
background: ${({bgtrans}) => (bgtrans ? '' : '#F2D7D5')};
white-space: nowrap;
padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
color: ${({dark}) => (dark ? '#273746' : '#FBFCFC')};
font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
outline: none;
border: #FBFCFC;
border-style: solid;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2s ease-in-out;
text-decoration:none;

&:hover{
    transition: all 0.2s ease-in-out;
    background: ${({bgtrans}) => (bgtrans ? '#F2D7D5' : '#fff')};
    color: ${({dark}) => (dark ? '#fff' : '#273746')};
}

`

