import styled from "styled-components";

export const TitleContainer = styled.div`
    width: 100% auto;
    align-items: center;
    background: #FBFCFC;

`

export const TitleHeading = styled.h1`
    text-align: center;
    text-transform: uppercase;
    padding: 0.5rem;
    letter-spacing: 0.5rem;
    font-size: 2rem;
    color: #273746;
    
    @media screen and (max-width: 767px){
       font-size: 1rem;
    }
`
export const FoodContainer = styled.div`
    position: relative;
    width: 50%;
    background: #FBFCFC;
    padding: 0.5rem;
    float: left;
 
    @media screen and (max-width: 1000px){
       width: 100%;
       left: 0%;
    }
    @media screen and (max-width: 768px){
       width: 100%;
       left: 0%;
    }
`

export const FoodDetails = styled.div`
    position: relative;
    width: 100%;
    height: 180px;
    float: left;
    justify-content: center;
    color: #273746;
    padding: 1rem;
    background: #FBFCFC;
    border-style:outset;
    
`

export const FoodImage = styled.img`
   width: 25%;
   height: 100%;
   position: relative;
   margin-left: 75%;
   margin-top: -20%;

`

export const FoodHeading = styled.h1`
    font-size: 1.8rem;
    font-weight: 600;
    max-width: 75%;
    @media screen and (max-width: 1024px){
        font-size: 1.5rem;
    }
    @media screen and (max-width: 768px){
        font-size: 1.1rem;
        max-width: 170px;

    }
`

export const FoodPrice = styled.h1`
    position: absolute;
    margin-top: 0rem;
    margin-left: 30rem;
    font-size: 2rem;
    font-weight: 600;
    color:#273746;

    @media screen and (max-width: 1024px){
       margin-left: 22rem;
       font-size: 2rem;
    }
    @media screen and (max-width: 880px){
        margin-left: 39rem;
        font-size: 2rem;
    }
    @media screen and (max-width: 767px){
        margin-left: 19rem;
        font-size: 1.5rem;
    }
    @media screen and (max-width: 380px){
        margin-left: 15.5rem;
        font-size: 1.3rem;
    }
    @media screen and (max-width: 320px){
        margin-left: 13rem;
        font-size: 1.3rem;
    }
    @media screen and (max-width: 280px){
        margin-left: 11.5rem;
        font-size: 1.2rem;
    }
 
`

export const FoodDescription = styled.p`
    
    max-width: 75%;
    font-size: 0.9rem;
    
    @media screen and (max-width: 1024px){
        font-size: 0.7rem;
    }
    @media screen and (max-width: 768px){
        font-size: 0.5rem;
        max-width: 170px;
    }
    
`