import styled from 'styled-components'


export const AboutusCointainer =styled.div`
        color: #273746;  
        display: flex;
        position: relative;
        background: #FBFCFC;
        border-color: #273746;
        border-style:solid;
        border-radius: 100px;
        margin: 10px;
        
        

    @media screen and (max-width: 768px){
        padding: 20px 0;
        height: 900px;
    }
    @media screen and (max-width: 320px){
        padding: 20px 0;
        height: 1000px;
    }
    
`
// export const PhotoBg = styled.img`
//     width: 90%;
//     height: 90%;
//     -o-object-fit: cover;
//     object-fit: contain;
//     background-color: whitesmoke;
//     border-radius: 500px;
//     padding: 30px;
    
    
// `
export const Details = styled.div`
    z-index: 3;
    height: 650px;
    width: 100% auto;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px;
    justify-content: center; 
    
    
    
    
`
export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 767px){
        font-size:1.5rem;
    }
`
export const Title = styled.p`
    margin-bottom: 20px;
    font-size: 1.3rem;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    font-style: italic;
    @media screen and (max-width: 767px){
        font-size:1.1rem;
        margin-bottom: 20px;
}
`

export const Description = styled.p`
    margin-bottom: 35px;
    font-size: 1.1rem;
    line-height: 35px;
    text-align: center;
    @media screen and (max-width: 767px){
        font-size:0.9rem;
        line-height: 25px;
    }
    @media screen and (max-width: 280px){
        line-height: 20px;
        font-size: 0.8rem;
    }
    
`