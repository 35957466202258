import React, {useState} from 'react'
import { Button, PDFButton } from '../ButtonElements'
import image from '../../images/INDISCHES RESTAURANT GANESHA HOME.jpg';

import { ArrowForward, ArrowRight, HeroBg, HeroBtnWrapper, HeroContainer, HeroContent, HeroH1, PhotosBg } from './HeroElements'
import GANESHALNCHMENUPDF from '../../Files/GANESHA INDISCHES RESTAURANT LUNCH MENU.pdf';

const HeroSection = () => {
    const[hover, setHover] = useState(false)

    const onHover =() => {
        setHover(!hover)
    }
    const[hoverr, setHoverr] = useState(false)

    const onHoverr =() => {
        setHoverr(!hoverr)
    }
    return (
        <HeroContainer id = 'home'>
            <HeroBg>
                <PhotosBg src={image} alt="INDISCHES RESTAURANT GANESHA PHOTO"></PhotosBg>
            </HeroBg>
            <HeroContent>
                <HeroH1>INDISCHES RESTAURANT</HeroH1>
                <HeroH1>GANESHA </HeroH1>
                <HeroBtnWrapper>
                    <Button to ="/menu" onMouseEnter ={onHover} onMouseLeave ={onHover} bgtrans={true} dark={false} >
                    Menükarte {hover ? <ArrowForward />: <ArrowRight />}
                    </Button>
                    <br></br>
                    <PDFButton href={GANESHALNCHMENUPDF} target="_blank" onMouseEnter ={onHoverr} onMouseLeave ={onHoverr} bgtrans={true} dark={false} >
                    Mittagsmenü {hoverr ? <ArrowForward />: <ArrowRight />}
                    </PDFButton>
                    
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
