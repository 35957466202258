import React from 'react'
import { AboutusCointainer,Details, Heading, Title, Description,PhotoBg } from './AboutusElements'


const Aboutus = () => {
    return (
        <>
            <AboutusCointainer id='about'>
                <Details>
                    <Heading>
                        Indisches Restaurant "Ganesha"
                    </Heading>
                    <Title>
                        Herzlich Willkommen <br></br> Welcome
                    </Title>
                    <Description>
                        Sehr verehrte Gäste, <br></br> wir möchten Sie in unserem Restaurant Ganesha herzlichst begrüßen. <br></br> Im Restaurant Ganesha benutzen wir nur die feinsten und frischesten Zutaten, um nahrhafte sowie köstliche Gerichte zuzubereiten. <br></br> Alle verwendeten Gewürze und Kräuter sind nicht nur für den Geschmack und das Aroma zuständig, sondern auch für die Verdauung und Ernährung nützlich. <br></br> Chili ist reich an Vitamin C. Fengureek (Bockshornklee) belebt die Leber. Ingwer und Pfefferkörner helfen der Verdauung. Koriander ist gut für die Nieren. <br></br> Gutes und gesundheitsförderndes Essen sind eng miteinander in der indischen Küche verbunden. <br></br> Wir sind immer bestrebt, <br></br> daß unsere Gäste als Freunde <br></br> unser Restaurant verlassen.
                    </Description>
                </Details>
            </AboutusCointainer>   
        </>
    )
}

export default Aboutus
